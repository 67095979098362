<template>
  <div>
    <loading v-if="isLoading || !contract" />
    <div v-else>
      <b-message type="is-info">
        <p>
          Current plan: <strong>{{ $_.get(contract, "product.name") }}</strong>
        </p>
        <p>
          Current cost:
          <strong>
            {{
              $formatCurrency(
                $_.get(contract, "price.total"),
                $_.get(contract, "price.currency")
              )
            }}
            every
            {{
              $_.get(contract, "product.term.length") === 1
                ? `month`
                : `${$_.get(contract, "product.term.length")} months`
            }}
          </strong>
        </p>
      </b-message>
      <no-results
        v-if="$_.isEmpty(options)"
        icon="arrow-circle-up"
        title="No upgrade option"
        message="Your current plan can't be upgraded"
      />
      <table
        v-else
        class="table is-bordered is-hoverable is-striped is-fullwidth"
      >
        <template v-for="(group, index) in groupedByProduct">
          <thead :key="`thead-${index}`" class="has-margin-bottom-200">
            <tr>
              <th colspan="5">{{ $_.get(group, "[0].productName") }}</th>
            </tr>
          </thead>
          <tbody :key="`tbody-${index}`" class="has-margin-top-200">
            <tr>
              <th></th>
              <th>Plan</th>
              <th>Upgrade cost</th>
            </tr>

            <tr
              v-for="(option, idx) in group"
              :key="idx"
              class="has-cursor-pointer"
              @click="selectPlan(option)"
            >
              <td width="5%" class="vcenter">
                <b-radio
                  :value="$_.get(selected, 'termId')"
                  :native-value="option.termId"
                  @input="selectPlan(option)"
                />
              </td>
              <td>
                <p>{{ option.name }}</p>
                <p class="is-size-7 has-text-grey">
                  Renews every
                  {{
                    $_.get(option, `termLength`) === 1
                      ? `month`
                      : `${$_.get(option, `termLength`)} months`
                  }}
                </p>
              </td>
              <td>
                <p>
                  <strong
                    >Pay
                    {{
                      $formatCurrency(
                        $_.get(option, "order.total"),
                        $_.get(option, "order.currency")
                      )
                    }}
                    today.</strong
                  >
                </p>
                <p class="is-size-7 has-text-grey">
                  Then
                  {{
                    $formatCurrency(
                      $_.get(option, `termPrice`),
                      $_.get(option, "order.currency")
                    )
                  }}
                  from
                  {{ $moment(option.dateNextDue).format("MMM Do, YYYY") }}
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectUpgdarePlan",
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      options: [],
      selected: null
    };
  },
  computed: {
    groupedByProduct() {
      return this.$_.groupBy(this.options, "productId");
    }
  },
  created() {
    return this.$store
      .dispatch("billing/getUpgradeOptions", {
        contractId: this.contract._id
      })
      .then(data => {
        this.options = data;
      })
      .catch(message => {
        this.$toast.open({
          message,
          type: "is-danger"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    selectPlan(row) {
      this.selected = row;
      this.$emit("cartdata", {
        productId: this.$_.get(row, "productId", null),
        productTermId: this.$_.get(row, "termId", null)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  td {
    vertical-align: middle;
  }
  thead:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
}
</style>
